import {HostButton} from "../components/components"
import {$roundWinners, $themes, imagePromiseFromResult, playAgain} from "../game"

import Confetti from "react-confetti"
import {SubmissionCore, SubmissionPlayerOverlay} from "../components/Submission"

export function WinnerScreen({winningRound}: {winningRound: number}) {
  const twTh = "text-left"
  const twPlace = "w-16"
  const {image, playerId, prompt} = $roundWinners.mustGetItem(winningRound)
  return (
    <div>
      <div>
        <Confetti recycle={false} numberOfPieces={500} />
        <SubmissionCore
          image={imagePromiseFromResult(image)}
          text={prompt}
          theme={$themes.mustGetItem(winningRound)}
        >
          <SubmissionPlayerOverlay playerId={playerId}>
            <div className="text-3xl mt-2 align-bottom">Winner!</div>
          </SubmissionPlayerOverlay>
        </SubmissionCore>
      </div>
      <div>
        <HostButton
          label="Play Again (same players)"
          onClick={() => {
            playAgain()
          }}
        />
        <HostButton
          label="Exit to home screen"
          onClick={() => {
            location.href = "/"
          }}
        />
      </div>
    </div>
  )
}
