import {REMOVE_ITEM} from "../livestate/shared/msg"
import {getOrSet, makeFactory} from "../shared/utils/builtins"
import {Atom, WritableAtom} from "./atom"
import {UNKNOWN_KEYS} from "./batch"
import {computed} from "./computed"
import {Unsubscribe} from "./events"
import {ItemAtom, ItemAtomSetMethods, processOnChangeItems} from "./itemAtom"

export type MapAtom<K = unknown, V = unknown> = Omit<
  WritableMapAtom<K, V>,
  ItemAtomSetMethods | "removeItem"
>

export class WritableMapAtom<K = unknown, V = unknown>
  extends WritableAtom<ReadonlyMap<K, V>>
  implements ItemAtom<K, V>
{
  private itemAtomsCache = new Map<K, Atom<V | undefined>>()

  constructor(value?: Map<K, V>) {
    super(value ?? new Map())
  }

  [Symbol.iterator]() {
    return this.value[Symbol.iterator]()
  }

  $item(key: K) {
    return getOrSet(this.itemAtomsCache, key, () => computed(($) => $(this).get(key)))
  }

  getItem(key: K) {
    return this.value.get(key)
  }

  mustGetItem(key: K) {
    const item = this.getItem(key)
    if (item == null) throw Error(`atom item "${key}" is ${item}`)
    return item
  }

  get size() {
    return this.value.size
  }

  keys() {
    return this.value.keys()
  }

  values() {
    return this.value.values()
  }

  has(key: K) {
    return this.value.has(key)
  }

  setToItems(items: Iterable<[K, V]>) {
    this.set(new Map(items))
  }

  setItems(items: Iterable<[K, V | typeof REMOVE_ITEM]>) {
    const newMap = new Map(this.value)
    const changedKeys = []
    for (const [key, value] of items) {
      if (value === REMOVE_ITEM) {
        if (!newMap.has(key)) continue
        newMap.delete(key)
      } else {
        if (newMap.has(key) && newMap.get(key) === value) continue
        newMap.set(key, value)
      }
      changedKeys.push(key)
    }
    if (changedKeys.length) {
      this._setWithKeys(newMap, changedKeys)
      return true
    }
    return false
  }

  setItem(key: K, itemValue: V) {
    this.setItems([[key, itemValue]])
    return this
  }

  removeItem(key: K) {
    return this.setItems([[key, REMOVE_ITEM]])
  }

  override onChange(
    listener: (value: Map<K, V>, oldValue: Map<K, V>, keys: K[] | UNKNOWN_KEYS) => void,
  ): Unsubscribe {
    return super.onChange(listener as any)
  }

  onItemChange(listener: (key: K, value: V | undefined, oldValue: V | undefined) => void) {
    return this.onChange((newValue, oldValue, keys) => {
      processOnChangeItems(newValue, oldValue, keys, (key) => {
        const newItemValue = newValue.get(key)
        const oldItemValue = oldValue.get(key)
        if (!this.isEqual(oldItemValue, newItemValue)) {
          listener(key, newItemValue, oldItemValue)
        }
      })
    })
  }
}

export const mapAtom = makeFactory(WritableMapAtom)
