import {Merge} from "type-fest"
import {ignoreUnhandledRejection} from "../../shared/utils/builtins"

export interface PendingPromiseLike<T> extends PromiseLike<T> {
  status: "pending"
}

export interface FulfilledPromiseLike<T> extends PromiseLike<T> {
  status: "fulfilled"
  value: T
}

export interface RejectedPromiseLike<T> extends PromiseLike<T> {
  status: "rejected"
  reason: unknown
}

export type PromiseLikeWithStatus<T> =
  | PendingPromiseLike<T>
  | FulfilledPromiseLike<T>
  | RejectedPromiseLike<T>

export type PromiseWithStatus<T> = Merge<
  Promise<T> & Readonly<PromiseLikeWithStatus<T>>,
  {
    then: <TResult1 = T, TResult2 = never>(
      onfulfilled: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
      onrejected: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null,
    ) => PromiseWithStatus<TResult1 | TResult2>

    catch: <TResult = never>(
      onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null,
    ) => PromiseWithStatus<T | TResult>

    finally: (onfinally?: (() => void) | undefined | null) => PromiseWithStatus<T>

    ignoreUnhandledRejection: () => PromiseWithStatus<T>
  }
>

export interface PromiseWithStatusConstructor {
  readonly prototype: PromiseWithStatus<unknown>
  // It's tempting to use PromiseWithStatus<any> here, but that can end up infecting other types
  // when, e.g., this value is assigned in one branch of a ternary.
  readonly pending: PromiseWithStatus<unknown>

  new <T>(
    executor: (
      resolve: (value: T | PromiseLike<T>) => void,
      reject: (reason?: any) => void,
    ) => void,
  ): PromiseWithStatus<T>

  all: (<T extends readonly unknown[] | []>(
    values: T,
  ) => PromiseWithStatus<{-readonly [P in keyof T]: Awaited<T[P]>}>) &
    (<T>(values: Iterable<T | PromiseLike<T>>) => PromiseWithStatus<Awaited<T>[]>)
  race: (<T extends readonly unknown[] | []>(values: T) => PromiseWithStatus<Awaited<T[number]>>) &
    (<T>(values: Iterable<T | PromiseLike<T>>) => PromiseWithStatus<Awaited<T>>)
  reject: <T = never>(reason?: any) => PromiseWithStatus<T>
  resolve: (() => PromiseWithStatus<void>) &
    (<T>(value: T) => PromiseWithStatus<Awaited<T>>) &
    (<T>(value: T | PromiseLike<T>) => PromiseWithStatus<Awaited<T>>)
}

class _PromiseWithStatus<T> extends Promise<T> {
  static readonly pending = new _PromiseWithStatus<any>(() => {}) as any as PromiseWithStatus<any>

  status: "pending" | "fulfilled" | "rejected" = "pending"
  value?: T
  reason?: unknown
  constructor(
    executor: (
      resolve: (value: T | PromiseLike<T>) => void,
      reject: (reason?: any) => void,
    ) => void,
  ) {
    const setFulfilled = (value: T) => {
      this.status = "fulfilled"
      this.value = value
    }
    const setRejected = (reason: unknown) => {
      this.status = "rejected"
      this.reason = reason
    }
    let resolve!: (value: T | PromiseLike<T>) => void
    let reject!: (reason?: any) => void

    super((_resolve, _reject) => {
      // We can't access `this` until after super() returns, so we save the args and use them below.
      resolve = _resolve
      reject = _reject
    })

    try {
      executor(
        (value) => {
          if (typeof (value as any)?.then === "function") {
            ;(value as PromiseLike<T>).then(setFulfilled, setRejected)
          } else {
            setFulfilled(value as T)
          }
          resolve(value)
        },
        (reason) => {
          if (typeof reason?.then === "function") {
            ;(reason as PromiseLike<T>).then(setFulfilled, setRejected)
          } else {
            setRejected(reason)
          }
          reject(reason)
        },
      )
    } catch (err) {
      setRejected(err)
      reject(err)
    }
  }

  ignoreUnhandledRejection() {
    return ignoreUnhandledRejection(this)
  }
}

export const PromiseWithStatus = _PromiseWithStatus as any as PromiseWithStatusConstructor
