import {izip, map} from "itertools"
import {$isHost} from "../livestate/liveContext"
import {$botIds, $names} from "../livestate/playerAtoms"
import {rpc} from "./client"
import {generateImageForGameWithTemplate, noResponseText, submitPrompt} from "./game"

export async function generateBotSubmissions(round: number, theme: string) {
  if ($botIds.size === 0) return

  let prompts: string[]
  try {
    prompts = (await rpc.generatePrompt.mutate({theme, numPrompts: $botIds.size})).prompts
  } catch (err) {
    console.error(err)
    prompts = map($botIds.get(), (botId) => noResponseText(botId))
  }

  // If we somehow dropped out and rejoined as a non-host while waiting for the response,
  // bail out now (we could in theory still set the result as a non-host but PlayerAtom won't
  // let a non-host set another player's state, and I'd prefer to keep that invariant.)
  if (!$isHost.get()) return
  for (let [botId, prompt] of izip($botIds.get(), prompts)) {
    prompt = prompt.replace(/^Create a/, "A")
    const image = generateImageForGameWithTemplate({
      model: "flux/schnell",
      size: "square",
      completion: prompt,
      template: theme,
      isPromptGenerated: true,
      round,
      playerName: $names.getItem(botId)!,
    })
    void submitPrompt(botId, prompt, image)
  }
}
