import {useMemo, useRef} from "react"
import * as R from "remeda"
import {$playerIds, myId} from "../../livestate/liveContext"
import {$names} from "../../livestate/playerAtoms"
import {useLiveLeader} from "../../livestate/react"
import {computed} from "../../xignal/computed"
import {useOnceWhenHostAnd} from "../../xignal/react"
import {AvatarIcon} from "../AvatarIcon"
import {fillPromptTemplate} from "../client"
import {twButton, twScreenTitle} from "../components/styles"
import {$round, $roundWinners, $themes, finalRound, isDevMode, setScreen} from "../game"
import {useTimeLeft} from "../react/hooks"
import {themes} from "../themes"
import {cn} from "../utils/css"
import {numRounds} from "./interstitials"

export function ThemeWaitScreen({themeChooser}: {themeChooser: string}) {
  return (
    <div className="flex flex-col items-center justify-center absolute inset-0 text-xl gap-1">
      <div className="flex items-center gap-1 justify-center">
        <AvatarIcon size={28} playerId={themeChooser} />
        <div>{$names.getItem(themeChooser)}</div>
      </div>
      <div>is choosing a prompt...</div>
    </div>
  )
}
export function ThemeChooserScreen() {
  const themeChoices = useMemo(() => {
    if ($round.get() === finalRound) {
      return R.range(0, numRounds - 1).map((round) => {
        let {prompt} = fillPromptTemplate({
          template: $themes.mustGetItem(round),
          completion: $roundWinners.mustGetItem(round).prompt,
        })
        prompt = prompt.trim()
        if (!["?", ".", "!"].includes(prompt.at(-1)!)) {
          prompt += "."
        }
        return prompt + " AND ___"
      })
    }
    const pastThemes = $themes.values()
    const pastThemesSet = new Set(pastThemes)
    const unusedThemes = themes.filter((theme) => !pastThemesSet.has(theme))
    return R.sample(unusedThemes, 5)
  }, [])
  const inputRef = useRef<HTMLInputElement>(null)
  const timeLeft = useTimeLeft(15, 1000, (timeLeft) => {
    if (timeLeft <= 0) {
      submitTheme(R.sample(themeChoices, 1)[0]!)
    }
  })

  const submitTheme = (theme: string) => {
    $themes.setItem($round.get(), theme)
    setScreen("WriteScreen", {})
  }

  return (
    <div>
      <div className="font-bold pb-1 text-center">{Math.ceil(timeLeft)}</div>
      <div className={twScreenTitle}>Choose a prompt:</div>
      <div>
        {themeChoices.map((theme, i) => (
          <button className={cn(twButton, "mb-1")} key={i} onClick={() => submitTheme(theme)}>
            {theme}
          </button>
        ))}
        {isDevMode() && (
          <>
            <div className="text-center mt-5 mb-2">Or write your own:</div>
            <form
              className="flex gap-1"
              action="#" // Needed for correct "Go" label on iPhone keyboard
              onSubmit={(event) => {
                event.preventDefault()
                submitTheme(inputRef.current!.value.trim())
              }}
            >
              <input className="border border-yellow-800 w-full p-2 rounded-lg" ref={inputRef} />
              <button className={cn(twButton, "w-28")}>Submit</button>
            </form>
          </>
        )}
      </div>
    </div>
  )
}
export function ThemeScreen({themeChooser}: {themeChooser: string}) {
  useLiveLeader(themeChooser)
  // If themeChooser quits, change it to the host
  useOnceWhenHostAnd(
    useMemo(() => computed(($) => !$($playerIds).has(themeChooser)), [themeChooser]),
    () => setScreen("ThemeScreen", {themeChooser: myId}),
  )

  return myId === themeChooser ? (
    <ThemeChooserScreen />
  ) : (
    <ThemeWaitScreen themeChooser={themeChooser} />
  )
}
