// Adapted from https://github.com/ai/nanoevents
// Changes:
// - Implementation is TS instead of JS
// - Allows you to assign member functions without bind, e.g. `this.on = emitter.on`

interface EventsMap {
  [event: string]: any
}

export interface DefaultEvents extends EventsMap {
  [event: string]: (...args: any) => void
}

export type Unsubscribe = () => void

export function createEmitter<Events extends EventsMap = DefaultEvents>() {
  const events: {[E in keyof Events]?: Events[E][]} = {}
  return {
    emit: <K extends keyof Events>(event: K, ...args: Parameters<Events[K]>) => {
      for (const callback of events[event] ?? []) {
        callback(...args)
      }
    },
    on: <K extends keyof Events>(event: K, cb: Events[K]): Unsubscribe => {
      ;(events[event] ??= []).push(cb)
      return () => {
        const cbs = events[event] ?? []
        const index = cbs.indexOf(cb)
        if (index !== -1) {
          cbs.splice(index, 1)
        }
      }
    },
  }
}
