/////
// This file should be kept in sync between the livestate server and client repos.
/////

import SuperJSON from "superjson"
import {Msg} from "./msg"

const HEARTBEAT_GRACE_MS = 3000

export function initHeartbeat(ws: WebSocket, heartbeatMs: number, onTimeout: () => void) {
  // Using any because of stupid nodejs types in ai-party
  let sendingTimeoutId: any
  let receivingTimeoutId: any

  function restartSendingTimeout() {
    clearTimeout(sendingTimeoutId)
    sendingTimeoutId = setTimeout(() => {
      const pingMsg: Msg = {p: 0}
      // as any because in ai-party we change the type of JSON.stringify to encourage jsonStringify,
      // but because this file is shared we want to keep it simple.
      ws.send(SuperJSON.stringify(pingMsg))
    }, heartbeatMs)
  }

  function restartReceivingTimeout() {
    clearTimeout(receivingTimeoutId)
    receivingTimeoutId = setTimeout(() => {
      console.log("Heartbeat timed out")
      onTimeout()
    }, heartbeatMs + HEARTBEAT_GRACE_MS)
  }

  function clearTimeouts() {
    clearTimeout(sendingTimeoutId)
    clearTimeout(receivingTimeoutId)
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const origSend = ws.send
  ws.send = function (this: WebSocket, message: any) {
    restartSendingTimeout()
    return origSend.call(this, message)
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const origClose = ws.close
  // Listening to the close event isn't enough, because it won't fire for 30 seconds in the case
  // of a broken connection (while it tries to close "cleanly")
  ws.close = function (this: WebSocket, code?: number, reason?: string) {
    clearTimeouts()
    return origClose.call(this, code, reason)
  }

  ws.addEventListener("open", () => {
    restartSendingTimeout()
    restartReceivingTimeout()
  })
  ws.addEventListener("message", () => {
    restartReceivingTimeout()
  })
  ws.addEventListener("close", clearTimeouts)
  ws.addEventListener("error", clearTimeouts)
}
