import {ReactNode} from "react"
import {ScreenName, ScreenProps, setScreen} from "../game"

export type InterstitialProps = {
  title: string
  content: ReactNode
  duration?: number
}
export type InterstitialName = keyof typeof interstitials

const roundInterstitials = {
  round0: {
    title: "Round 1️⃣",
    content: "Welcome to Prompt Monster 🎉",
  },
  round1: {
    title: "Round 2️⃣",
    content: "",
  },
  round2: {
    title: "Round 3️⃣",
    content: "",
  },
  round3: {
    title: "Round 4️⃣: The Remix",
    content: "Final round. Points are doubled.",
  },
}

export const interstitials = {
  ...roundInterstitials,
  ratings: {
    title: "Ratings",
    content: "Rate each image. The highest score will win.",
  },
  showcase: {
    title: "Showcase",
    content: "The host will cycle through the images.",
  },
  vote: {
    title: "Vote",
    content: "Tap an image to cast your vote",
  },
  finalVote: {
    title: "Final Vote",
    content: "Vote for your favorite of the winning images",
  },
  winner: {
    title: "And the winner is...",
    content: "",
  },
} satisfies Record<string, InterstitialProps>

export const numRounds = Object.keys(roundInterstitials).length

export function setInterstitial<T extends ScreenName>(
  interstitialName: InterstitialName,
  screenName: T,
  props: ScreenProps<T>,
) {
  setScreen("InterstitialScreen", {
    name: interstitialName,
    nextScreenName: screenName,
    nextScreenProps: props,
  })
}
