import {nanoid} from "nanoid"
import {WebSocket} from "partysocket"
import SuperJSON from "superjson"
import {getOrSetItem, mustStringProp} from "../shared/utils/builtins"
import {mustFetchJson} from "../shared/utils/fetch"
import {atom} from "../xignal/atom"
import {UNKNOWN_KEYS, batch} from "../xignal/batch"
import {computed} from "../xignal/computed"
import {ItemAtom} from "../xignal/itemAtom"
import {SetAtom} from "../xignal/setAtom"
import {Live, LiveAtom, liveAtom, liveSetAtom} from "./liveAtom"
import {LiveEvent, eventsByName} from "./liveEvent"
import {BaseMsg, Msg, createMsg, msgSchema, optimizeMsg} from "./shared/msg"
import {initHeartbeat} from "./shared/socketHeartbeat"

export type PlayerId = string & {} // `& {}` keeps alias name in intellisense
export const ANY_PLAYER_LEADER = Symbol("ANY_PLAYER_LEADER")
export const NO_LEADER = Symbol("NO_LEADER")
export type Leader = PlayerId | typeof ANY_PLAYER_LEADER | typeof NO_LEADER

type UpdateMode = "confirmed" | "optimistic"
export const UNINITIALIZED_HOST = "UNINITIALIZED_HOST"

export class LiveContext {
  #ws: WebSocket | undefined
  #roomCode: string | undefined
  #shouldResetState = false
  #serverUrl = "https://livestate.qed9.com"
  #pingInterval = 5000
  #pendingUpdates:
    | {msg: Msg & Required<BaseMsg>; items: Map<string, Map<unknown, unknown>>}
    | undefined

  // NB: We no longer need this global view of all atoms that have unconfirmed data.
  // We could move this to a field directly on each LiveAtom.
  /** @internal */
  _unconfirmedVersions = new Map<LiveAtom, Map<unknown, number>>()
  /** @internal */
  _liveAtoms = new Map<string, LiveAtom>()
  /** @internal */
  _liveEvents = new Map<string, LiveEvent>()
  /** @internal */
  _updateMode: UpdateMode = "optimistic"
  /** @internal */
  _stateVersion = 0

  // These rely on stuff above being initialized (via `this` being passed as arg)
  readonly $hostId = liveAtom("hostId", UNINITIALIZED_HOST, this)
  readonly $joined = computed((watch) => watch($hostId) !== UNINITIALIZED_HOST)
  readonly $connected = atom(false)
  readonly $isHost = computed(($) => $(this.$hostId) === myId)
  readonly $leader = atom<Leader>(NO_LEADER)
  readonly $playerIds = liveSetAtom<PlayerId>("playerIds", undefined, this) as Live<
    SetAtom<PlayerId>
  > // readonly

  get roomCode() {
    return this.#roomCode
  }

  setServerUrl(url: string) {
    if (this.#roomCode) {
      throw new Error("setServerUrl can only be called when disconnected from a room")
    }
    this.#serverUrl = url
  }

  #batchWithMode<T>(updateMode: UpdateMode, callback: () => T): T {
    return batch(() => {
      const oldMode = this._updateMode
      this._updateMode = updateMode
      try {
        return callback()
      } finally {
        // We reset this before the end of the batch callback; so it won't apply to event listeners
        this._updateMode = oldMode
      }
    })
  }

  #sendUpdates() {
    if (!this.#pendingUpdates) return
    const {msg, items} = this.#pendingUpdates
    for (const [liveKey, itemUpdates] of items) {
      msg.i[liveKey] = [...itemUpdates.entries()]
    }
    optimizeMsg(msg)
    this.#ws!.send(SuperJSON.stringify(msg))
    this.#pendingUpdates = undefined
  }

  /** @internal */
  _scheduleUpdate() {
    if (!this.#ws) throw new Error("You must be connected to a room before setting live state")
    if (!this.#pendingUpdates) {
      this.#pendingUpdates = {msg: createMsg(++this._stateVersion), items: new Map()}
      queueMicrotask(() => {
        this.#sendUpdates()
      })
    }
    return this.#pendingUpdates
  }

  leaveRoom() {
    if (!this.#ws) return
    console.log("Leaving room", this.#roomCode)

    this.#sendUpdates()
    this.#ws.close()
    this.#ws = undefined

    // NB: the onclose handler also sets a flag to call resetAtoms when the next connection is
    // opened, but we do immediately here when explicitly leaving the room.
    this.#batchWithMode("confirmed", () => this.#resetState())
    this.#roomCode = undefined
  }

  resetAtoms = ({exclude}: {exclude?: Set<LiveAtom>}) => {
    for (const $atom of this._liveAtoms.values()) {
      if (!exclude?.has($atom)) $atom.reset()
    }
  }

  #resetState() {
    batch(() => {
      // We clear out all state, including pending changes, because non-player atoms may be stale,
      // changed by whoever took over as host. At some point, we could keep and re-send pending
      // changes to player atoms using _unconfirmedVersions, but for now we just reset all pending
      // changes when reconnecting.
      for (const $atom of this._liveAtoms.values()) {
        $atom.reset()
        // This gets used in "confirmed" mode, so we need to manually reset $confirmed state
        $atom.$confirmed.reset()
      }
      this._unconfirmedVersions.clear()
    })
  }

  async createRoom(): Promise<string> {
    const res = (await mustFetchJson(this.#serverUrl + `/room`, {method: "POST"})) as any
    const roomCode = mustStringProp(res, "roomCode")
    return roomCode
  }

  joinRoom(roomCode: string): Promise<void> {
    if (this.#roomCode) throw new Error("Already joined/joining a room. Call leaveRoom first.")
    console.log("Joining room", roomCode)
    this.#roomCode = roomCode

    // Resolves/rejects after first message/error/close
    return new Promise<void>((resolve, reject) => {
      const urlParams = new URLSearchParams({
        playerId: myId,
        pingMs: String(this.#pingInterval),
      })
      const ws = (this.#ws = new WebSocket(
        this.#serverUrl + `/join/${roomCode}?${urlParams}`,
        undefined,
        // We don't want to send changes to non-player atoms when reconnecting, because they
        // may be stale, changed by whoever took over as host. At some point, we could implement
        // our own system of sending pending player atom changes using _unconfirmedVersions, but
        // for now we just reset all pending changes when reconnecting.
        // UPDATE: temporarily commented out until I implement resending pending player atoms
        // {maxEnqueuedMessages: 0},
      ))
      initHeartbeat(ws as any, this.#pingInterval, () => {
        // https://github.com/partykit/partykit/blob/9cb3fe9bd99a821b3ccd687c49fb6ff0aa119b98/packages/partysocket/src/ws.ts#L514
        ;(ws as any)._disconnect()
      })
      ws.onmessage = (event) => {
        // We can still get messages after calling close(), before the closing handshake is received
        // (while readyState is CLOSING); this ignores any such messages (and protects against bugs
        // if somehow an old websocket remains open, ensuring only one active websocket)
        if (ws !== this.#ws) return

        if (event.data === "BADROOM") {
          // The server will also close the connection, but this prevents PartySocket from
          // continuously retrying the connection.
          ws.close()
          this.#ws = undefined
          this.#roomCode = undefined
          reject("Invalid room code: " + roomCode)
          return
        }
        const msg = msgSchema.parse(SuperJSON.parse(event.data))
        this.#batchWithMode("confirmed", () => {
          if (this.#shouldResetState) {
            this.#shouldResetState = false
            this.#resetState()
          }
          this.#processMsg(msg)
        })
        // We really only need to set this at the end of the first message after connecting, but
        // it's easier to just do it unconditionally.
        this.$connected.set(true)
        resolve()
      }
      // NB: Remember, this can fire in the middle of a session when the websocket is reconnecting.
      ws.onclose = (event) => {
        console.log("WS onclose")
        let errorExtra = ""
        if (!event.wasClean) {
          errorExtra = ` Code: ${event.code}. Reason: ${event.reason}`
          console.error("WebSocket closed with error." + errorExtra)
        }
        reject("Room closed before join completed" + errorExtra)

        // See ws.onmessage. We don't need to set #shouldResetAtoms in this case because if the
        // websockets are different, leaveRoom() must have been called, which already resets them.
        if (ws !== this.#ws) return
        // When we reconnect to the same room we want to clear all local state (to clear out pending
        // optimistic state and in case there are local items that are no longer on the server and
        // won't be sent down).
        this.#shouldResetState = true
        this.$connected.set(false)
      }
      ws.onerror = () => {
        reject("WebSocket error")
      }
    })
  }

  #processUnconfirmedVersions(
    $atom: LiveAtom,
    items: [unknown, unknown][],
    version: undefined | number,
    cb: (key: unknown, itemsMap: Map<unknown, unknown>) => void,
  ) {
    const unconfirmedVersion = this._unconfirmedVersions.get($atom)
    if (!unconfirmedVersion) return items

    // We don't overwrite our own unconfirmed updates -- we know it will be acked soon and don't
    // want the value to jump back and forth.
    try {
      const unknownKeysVersion = unconfirmedVersion.get(UNKNOWN_KEYS)
      if (unknownKeysVersion != null) {
        if (version == null || version < unknownKeysVersion) return UNKNOWN_KEYS
        if (version > unknownKeysVersion) {
          console.error("Ack version > unknownKeysVersion (previous ack wasn't processed)", {
            liveKey: $atom.liveKey,
            version,
            unknownKeysVersion,
          })
        }
        unconfirmedVersion.delete(UNKNOWN_KEYS)
      }

      let itemsMap: Map<unknown, unknown> | undefined
      for (const [unconfirmedKey, keyVersion] of unconfirmedVersion) {
        if (version != null && version >= keyVersion) {
          if (version > keyVersion) {
            console.error("Ack version > keyVersion (previous ack wasn't processed)", {
              liveKey: $atom.liveKey,
              key: unconfirmedKey,
              version,
              keyVersion,
            })
          }
          unconfirmedVersion.delete(unconfirmedKey)
        } else {
          itemsMap ??= new Map(items)
          cb(unconfirmedKey, itemsMap)
        }
      }
      return itemsMap?.entries() ?? items
    } finally {
      if (unconfirmedVersion.size === 0) {
        this._unconfirmedVersions.delete($atom)
      }
    }
  }

  #processMsg(msg: Msg) {
    const {s: sets = {}, m: setMaps = {}, i: setItems = {}, e: events = [], v: version} = msg

    for (const [name, ...args] of events) {
      const event = eventsByName.get(name)
      if (!event) throw Error(`Got unknown event "${name}"`)
      event._emit(...args)
    }

    // The server needs to treat setMaps differently because it doesn't have access to the atom
    // objects to know the difference. We do, so we merge them and process them together here.
    for (const [liveKey, val] of Object.entries(setMaps)) {
      if (liveKey in sets) throw Error(`Livekey found in both sets and setMaps: ${liveKey}`)
      sets[liveKey] = val
    }

    for (const [liveKey, val] of Object.entries(sets)) {
      const $atom = this._liveAtoms.get(liveKey)
      if (!$atom) {
        console.error(`No atom for liveKey: ${liveKey}`)
        continue
      }

      const unconfirmedVal = this.#processUnconfirmedVersions(
        $atom,
        val as any,
        version,
        (unconfirmedKey, itemsMap) => {
          itemsMap.set(unconfirmedKey, ($atom as any as ItemAtom).getItem(unconfirmedKey))
        },
      )
      if ("setToItems" in $atom) {
        if (unconfirmedVal !== UNKNOWN_KEYS) ($atom as any as ItemAtom).setToItems(unconfirmedVal)
        ;($atom.$confirmed as any as ItemAtom).setToItems(val as [unknown, unknown][])
      } else {
        if (unconfirmedVal !== UNKNOWN_KEYS) $atom.set(unconfirmedVal)
        $atom.$confirmed.set(val)
      }
    }

    for (let [liveKey, items] of Object.entries(setItems)) {
      const $atom = this._liveAtoms.get(liveKey)
      if (!$atom) {
        console.error(`No atom for liveKey: ${liveKey}`)
        continue
      }

      const unconfirmedItems = this.#processUnconfirmedVersions(
        $atom,
        items,
        version,
        (unconfirmedKey, itemsMap) => {
          itemsMap.delete(unconfirmedKey)
        },
      )

      if (unconfirmedItems !== UNKNOWN_KEYS) ($atom as any as ItemAtom).setItems(unconfirmedItems)
      ;($atom.$confirmed as any as ItemAtom).setItems(items)
    }
  }
}

export const live = new LiveContext()
export const {$joined, $connected, $isHost, $hostId, $leader, $playerIds, resetAtoms} = live
export const myId = getOrSetItem(sessionStorage, "livestate:myId", () => nanoid(8))

// For debugging
;(window as any)._live = live
