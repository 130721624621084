import {map} from "itertools"
import {range, sortBy} from "remeda"
import {playerAtom} from "../../livestate/liveAtom"
import {$playerIds, myId} from "../../livestate/liveContext"
import {hasAll, must} from "../../shared/utils/builtins"
import {computed} from "../../xignal/computed"
import {useAtom, useOnceWhenHostAnd} from "../../xignal/react"
import {rpc} from "../client"
import {WaitingForOthers} from "../components/components"
import {twScreenTitle} from "../components/styles"
import {SubmissionCore} from "../components/Submission"
import {$gameId, $roundWinners, $themes, imagePromiseFromResult} from "../game"
import {cn} from "../utils/css"
import {numRounds, setInterstitial} from "./interstitials"

const $votes = playerAtom<number>("finalVote")
const $hasAllVotes = computed((watch) => hasAll(watch($votes), watch($playerIds)))

export function FinalVoteScreen(props: {}) {
  const myVote = useAtom($votes.$mine)

  useOnceWhenHostAnd($hasAllVotes, () => {
    const votesByRound: number[] = []
    for (const voteRound of $votes.values()) {
      votesByRound[voteRound] ??= 0
      votesByRound[voteRound]++
    }

    const rankedRounds = sortBy(
      range(0, numRounds),
      [(round) => votesByRound[round] ?? 0, "desc"],
      (round) => [...$votes.values()].indexOf(round),
    )

    const imageVoteCounts: Record<string, number> = Object.create(null)
    for (const round of rankedRounds) {
      const imageResult = $roundWinners.getItem(round)?.image
      if (imageResult && "id" in imageResult) {
        imageVoteCounts[imageResult.id] = votesByRound[round] ?? 0
      }
    }
    void rpc.vote.mutate({gameId: must($gameId.get()), votes: imageVoteCounts})
    setInterstitial("winner", "WinnerScreen", {winningRound: rankedRounds[0]!})
  })

  if (myVote) {
    return <WaitingForOthers />
  }

  return (
    <div>
      <div className={cn(twScreenTitle, "italic")}>Vote for your favorite</div>
      {map($roundWinners.get(), ([round, {prompt, image}]) => {
        return (
          <SubmissionCore
            key={round}
            theme={$themes.mustGetItem(round)}
            image={imagePromiseFromResult(image)}
            text={prompt}
            onClick={() => {
              $votes.setItem(myId, round)
            }}
          />
        )
      })}
    </div>
  )
}
