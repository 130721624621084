import {makeFactory} from "../shared/utils/builtins"
import {createEmitter} from "../xignal/events"
import {didHmr} from "./lib/hmr"
import {LiveContext, live} from "./liveContext"

export const eventsByName = new Map<string, LiveEvent<any[]>>()
const emitter = createEmitter()

export class LiveEvent<T extends unknown[] = []> {
  #ctx: LiveContext

  constructor(
    readonly name: string,
    ctx = live,
  ) {
    if (ctx._liveEvents.has(name) && !didHmr) {
      throw new Error(`LiveEvent with name "${name}" already exists`)
    }
    ctx._liveEvents.set(name, this)
    this.#ctx = ctx
  }

  send(...args: T) {
    this.#ctx._scheduleUpdate().msg.e.push([this.name, ...args])
  }

  listen(callback: (...args: T) => void) {
    emitter.on(this.name, callback)
  }

  /** @internal */
  _emit(...args: T) {
    emitter.emit(this.name, ...args)
  }
}

export const liveEvent = makeFactory(LiveEvent)
