import {createBrowserRouter} from "react-router-dom"
import {Play} from "./App.tsx"
import {Layout} from "./Layout.tsx"
import {Playground} from "./Playground.tsx"
import {RouterError} from "./RouterError.tsx"
import {Start} from "./Start.tsx"
import {JoinScreen} from "./screens/Lobby.tsx"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <RouterError />,
    children: [
      {
        index: true,
        element: <Start />,
      },
      {
        path: "/playground",
        element: <Playground />,
      },
      {
        path: "/join",
        element: <JoinScreen />,
      },
      {
        path: "/play/:roomCode",
        element: <Play />,
      },
    ],
  },
])
