import * as Sentry from "@sentry/react"
import posthog from "posthog-js"
import React from "react"
import {createRoot} from "react-dom/client"
import {RouterProvider} from "react-router-dom"
import {myId} from "../livestate/liveContext"
import {$names} from "../livestate/playerAtoms"
import {$gameId, $screen} from "./game"
import "./index.css"
import {router} from "./router.tsx"
import {ErrorScreen} from "./screens/ErrorScreen.tsx"

const posthogDisabled = import.meta.env.DEV
const isTrackingAdmin = localStorage["trackingRole"] === "admin"

posthog.init(posthogDisabled ? "dummy" : "phc_xpoAO8UMjzKPPoLhWvi2qPmbfc6SataND0EV5VMxTDF", {
  api_host: `${location.origin}/bq`,
  person_profiles: "identified_only",
  // Autocapture can result in a bunch of requests, and I'm worried (probably irrationally) that it
  // could affect performance of the game if a large group is all sending these events at the same
  // time on the same network. Also, I just don't really care about most of the events, and the
  // irrelevant ones can get in the way when viewing the dashboard. And for the ones I do care
  // about, it's cleaner (and much easier to deal with in posthog) to have a separate event name via
  // postHog.capture.
  autocapture: false,
  advanced_disable_decide: posthogDisabled,
  disable_session_recording: posthogDisabled || isTrackingAdmin,
  // The only benefit of cookies is cross-domain tracking which we don't care about.
  // We don't want posthog cookies polluting non-posthog requests, so we use localstorage.
  // This also has the benefit of 7-day vs 24-hr expiration w/ Safari's ITP.
  persistence: "localStorage",
  sanitize_properties: (props) => {
    props["playerId"] = myId
    // So I can filter out my own events by manually setting this in localStorage
    props["trackingRole"] = localStorage["trackingRole"]
    props["gameId"] = $gameId.get() || undefined
    return props
  },
  loaded: (posthog) => {
    if (posthogDisabled) {
      posthog.opt_out_capturing()
    }
  },
})
;(window as any)._ph = posthog

Sentry.init({
  dsn: "https://a4f4a3067d0c98d88e0d4989578f1b03@o4506538978639872.ingest.sentry.io/4506539068489728",
  integrations: [],
  enabled: !import.meta.env.DEV,
  environment: import.meta.env.MODE,
  beforeSend(event) {
    // This can be undefined even though the types say otherwise
    const screenState = $screen.get()
    const screenName = screenState?.[0]
    event.tags ??= {}
    Object.assign(event.tags, {
      screen: screenName,
      // Intentionally setting these separate from Sentry's `user` tag, because these are ephemeral.
      "player.name": $names.getItem(myId),
      "player.id": myId,
    })
    return event
  },
})

const root = createRoot(document.getElementById("root")!)
root.render(
  <React.StrictMode>
    {/* Errors should rarely reach this since react-router has its own boundary, but it's here as a last backstop */}
    <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
      <RouterProvider router={router} />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)
