import posthog from "posthog-js"
import {use, useState} from "react"
import {useNavigate} from "react-router-dom"
import {randomInteger} from "remeda"
import {live} from "../livestate/liveContext"
import {HowToPlayLink} from "./components/HowToPlay"
import {twButton, twIcon, twLink, twRootLayout} from "./components/styles"
import IconDiscord from "./icons/IconDiscord"
import {cn} from "./utils/css"

const LOGO_ID = randomInteger(1, 4)

if (location.hostname === "localhost" || location.hostname.startsWith("192.168.")) {
  live.setServerUrl(`${location.protocol}//${location.hostname}:8787`)
}

export function Start() {
  const [promise, setPromise] = useState<Promise<void>>()
  if (promise) use(promise)
  const navigate = useNavigate()

  return (
    <div className={twRootLayout}>
      <img className="w-full aspect-square" src={`/logos/${LOGO_ID}.webp`} />
      <div>
        <button
          className={cn(twButton, "mb-1.5")}
          onClick={(e) => {
            e.currentTarget.disabled = true
            setPromise(live.createRoom().then((roomCode) => navigate(`/play/${roomCode}`)))
            posthog.capture("createGame:click")
          }}
        >
          Start new game
        </button>
        <button
          className={twButton}
          onClick={() => {
            posthog.capture("joinGame:click")
            navigate("/join")
          }}
        >
          Join game
        </button>
      </div>
      <div className="mt-6 text-center flex flex-col gap-px">
        <div>
          <HowToPlayLink />
        </div>
        <div>
          <a
            className={twLink}
            href="https://discord.gg/MPdQZmcBvp"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              posthog.capture("discord:click")
            }}
          >
            <IconDiscord className={twIcon} />
            Discuss on Discord
          </a>
        </div>
      </div>
    </div>
  )
}
