import {useRef, useState} from "react"
import * as R from "remeda"
import {liveAtom, roundScoped} from "../../livestate/liveAtom"
import {$isHost, myId} from "../../livestate/liveContext"
import {$botIds, $names, $playerAndBotIds} from "../../livestate/playerAtoms"
import {hasAll} from "../../shared/utils/builtins"
import {computed} from "../../xignal/computed"
import {useAtom, useOnceWhenHostAnd} from "../../xignal/react"
import {generateBotSubmissions} from "../bot"
import {GeneratedImage, fillPromptTemplate, promptTemplatePlaceholder, rpc} from "../client"
import {SubmissionCore} from "../components/Submission"
import {WaitingForOthers} from "../components/components"
import {twButton} from "../components/styles"
import {
  $gameMode,
  $round,
  $submissions,
  $theme,
  generateImageForGame,
  generateImageForGameWithTemplate,
  isDevMode,
  noResponseText,
  setScreen,
  submitPrompt,
} from "../game"
import {Use} from "../react/Use"
import {useEffectOnce, useTimeLeft} from "../react/hooks"
import {cn} from "../utils/css"
import {setInterstitial} from "./interstitials"

const $timeLeft = roundScoped(liveAtom("timeLeft", isDevMode() ? 9999 : 55))
const $hasAllSubmissions = computed((watch) => hasAll(watch($submissions), watch($playerAndBotIds)))

type Preview = {
  text: string
  image: Promise<GeneratedImage>
  key: number
}

export function WriteScreen(props: {}) {
  const inputRef = useRef<HTMLInputElement>(null)
  const round = useAtom($round)
  const theme = useAtom($theme)
  const submission = useAtom($submissions.$mine)
  const [previews, setPreviews] = useState<Preview[]>([])
  const [suggestionsPromise, setSuggestionsPromise] = useState<Promise<string[]>>()

  useEffectOnce(() => {
    if ($isHost.get()) void generateBotSubmissions(round, theme)
  })

  useOnceWhenHostAnd($hasAllSubmissions, () => {
    const submittedIds = R.shuffle([...$submissions.get().keys()])
    if ($gameMode.get() === "v1") {
      setInterstitial("vote", "VoteScreen", {
        submittedIds,
      })
    } else {
      setInterstitial("ratings", "RatingsScreen", {
        remainingIds: submittedIds,
      })
    }
  })

  const [timerStart] = useState(() => $timeLeft.get())
  const timeLeft = useTimeLeft(timerStart, 1000, (timeLeft) => {
    if ($isHost.get()) {
      // Used above to initialize the timer for late joiners
      $timeLeft.set(Math.round(timeLeft))
      if (timeLeft <= 0) {
        for (const botId of $botIds.get()) {
          if ($submissions.getItem(botId) == null) {
            // Must've been an error calling the API, or the original host dropped out before it
            // got the response.
            // TODO: submit "bot didn't respond" instead of kicking
            $botIds.delete(botId)
          }
        }
      }
    }
    if (timeLeft <= 0 && submission == null) {
      const text = previews[0]?.text || inputRef.current!.value.trim() || noResponseText(myId)
      const image =
        previews[0]?.image ??
        generateImageForGameWithTemplate({
          model: "flux/schnell",
          size: "square",
          completion: text,
          template: theme,
          isPromptGenerated: false,
          round,
          playerName: $names.getItem(myId)!,
        })
      submitPrompt(myId, text, image)
    }
  })

  const doPreviews = (text: string) => {
    const {prompt} = fillPromptTemplate({completion: text, template: theme})
    const variants = [
      ["flux/schnell", "square"],
      ["flux/schnell", "square"],
      ["fast-sdxl", "square_hd"],
    ] as const
    setPreviews(
      variants.map(([model, size], i) => ({
        text,
        image: generateImageForGame({
          model,
          size,
          prompt,
          template: theme,
          enhancePrompt: true,
          isPromptGenerated: false,
          round,
          playerName: $names.getItem(myId)!,
        }),
        key: i,
      })),
    )
  }

  return (
    <div className="text-center">
      <div className="font-bold pb-1">{Math.ceil(timeLeft)}</div>
      <div className="pb-2 text-lg italic">{theme}</div>
      {submission != null ? (
        <WaitingForOthers />
      ) : (
        <div>
          {previews.length === 0 ? (
            <form
              action="#" // Needed for correct "Go" label on iPhone keyboard
              onSubmit={(event) => {
                event.preventDefault()
                const text = inputRef.current!.value.trim()
                if (text !== "") doPreviews(text)
              }}
            >
              <input
                ref={inputRef}
                autoCapitalize={theme.startsWith(promptTemplatePlaceholder) ? "on" : "off"}
                className="w-full h-[46px] rounded-lg border border-yellow-800 outline-none py-2 px-3 text-lg mb-2"
              />
              <button className={twButton}>Submit</button>
              {isDevMode() && (
                <button
                  className={cn(twButton, "mt-1.5")}
                  onClick={() => {
                    setScreen("ThemeScreen", {
                      themeChooser: myId,
                    })
                  }}
                >
                  ThemeScreen
                </button>
              )}
              {!suggestionsPromise ? (
                <button
                  className={cn(twButton, "mt-1.5")}
                  onClick={() => {
                    setSuggestionsPromise(
                      rpc.generatePrompt.mutate({theme, numPrompts: 3}).then((res) => res.prompts),
                      // new Promise((resolve, reject) => {
                      //   setTimeout(() => {
                      //     reject("foo")
                      //     resolve(
                      //       rpc.generatePrompt
                      //         .mutate({theme, numPrompts: 3})
                      //         .then((res) => res.prompts),
                      //     )
                      //   }, 3000)
                      // }),
                    )
                  }}
                >
                  Get suggestions
                </button>
              ) : (
                <div className="mt-4 border border-gray-500 rounded-lg p-2 pt-2 bg-yellow-100">
                  <div className="pb-0.5 italic">Suggestions:</div>
                  <Use
                    promise={suggestionsPromise}
                    errorFallback={<div className="p-1">Sorry, suggestions are not available</div>}
                    suspenseFallback={<div className="p-1">Loading...</div>}
                  >
                    {(suggestions) => {
                      let iSuggestion = 0
                      return (
                        <div>
                          {suggestions.map((suggestion) => {
                            return (
                              <div key={iSuggestion++}>
                                <button
                                  className={cn(twButton, "mt-1.5 zbg-pink-200")}
                                  onClick={() => doPreviews(suggestion)}
                                >
                                  {suggestion}
                                </button>
                              </div>
                            )
                          })}
                        </div>
                      )
                    }}
                  </Use>
                </div>
              )}
            </form>
          ) : (
            <div>
              <div className="pb-3">
                <div>Tap your favorite image to submit it.</div>
              </div>
              {previews.map((preview) => (
                <SubmissionCore
                  key={preview.key}
                  theme={theme}
                  text={preview.text}
                  image={preview.image}
                  onClick={() => {
                    submitPrompt(myId, preview.text, preview.image)
                  }}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
