import {createTRPCClient, httpLink} from "@trpc/client"
import {inferRouterInputs, inferRouterOutputs} from "@trpc/server"
import type {AppRouter} from "../backend/router"

export type RpcInput = inferRouterInputs<AppRouter>
export type RpcOutput = inferRouterOutputs<AppRouter>

export type GeneratedImage = {id: string; url: string; enhancedPrompt: string | null}
export type TemplateAndCompletion = {template: string; completion: string}

export const promptTemplatePlaceholder = "___"
export function fillPromptTemplate(props: TemplateAndCompletion) {
  const prompt = props.template.replace(promptTemplatePlaceholder, props.completion)
  // const templateHash = await getShortHash(props.template)
  return {prompt}
}

// No global location when running via CLI
const origin = globalThis.location ? location.origin : "http://localhost:5223"
const url = `${origin}/api`
export const rpc = createTRPCClient<AppRouter>({links: [httpLink({url})]})
