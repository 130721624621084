import {map} from "itertools"
import {Writable} from "type-fest"
import {getOrSet} from "../shared/utils/builtins"
import {Atom, WritableAtom, atom} from "../xignal/atom"
import {UNKNOWN_KEYS, batch} from "../xignal/batch"
import {Unsubscribe} from "../xignal/events"
import {WritableMapAtom, mapAtom} from "../xignal/mapAtom"
import {setAtom} from "../xignal/setAtom"
import {didHmr} from "./lib/hmr"
import {$isHost, $leader, ANY_PLAYER_LEADER, LiveContext, PlayerId, live, myId} from "./liveContext"
import {REMOVE_ITEM} from "./shared/msg"

// NB: LiveAtoms are always set optimistically. Values are updated locally immediately, before the
// server acknowledges them. This greatly simplifies everything and is the desired behavior in most
// cases anyway. For cases where you only want values that have been confirmed by the server, use
// the `$confirmed` property.
//
// If an optimistically written update doesn't make it to the server, that means there's a
// connection issue and the socket should close at some point. When it reconnects, the latest
// server state will be sent down, and the client will update with that data.
//
// To avoid a situation where players think they are playing live while the socket it actually
// trying to reconnect, we could expose a reconnected event and clients could show a "reconnecting"
// lightbox (or similar).

export type Listener<T> = (value: T, oldValue: T) => void

export type Live<T extends Atom<any>> = T & {
  readonly liveKey: string
  readonly $confirmed: T
  onLocalSet: (listener: Listener<ReturnType<T["get"]>>) => Unsubscribe
  onHostLocalSet: (listener: Listener<ReturnType<T["get"]>>) => Unsubscribe
  _validateUpdate: (keys: unknown[] | UNKNOWN_KEYS) => void
}

export type LiveAtom<T = unknown> = Live<WritableAtom<T>>

export function isLiveAtom(atom: WritableAtom): atom is LiveAtom {
  return "liveKey" in atom
}

export function livify<T extends WritableAtom<any>>(
  liveKey: string,
  atomCreator: () => T,
  itemSerializer?: (
    value: ReturnType<T["get"]>,
    keys: unknown[] | UNKNOWN_KEYS,
  ) => [unknown, unknown][],
  ctx = live,
): Live<T> {
  if (ctx._liveAtoms.has(liveKey) && !didHmr) {
    throw new Error(`Live atom with liveKey "${liveKey}" already exists`)
  }

  const localSetListeners: Listener<ReturnType<T["get"]>>[] = []

  const $live = atomCreator() as Live<T>
  const $confirmed = atomCreator()
  ;($live as Writable<typeof $live>).$confirmed = $confirmed

  ctx._liveAtoms.set(liveKey, $live)
  ;($live.liveKey as Writable<typeof $live.liveKey>) = liveKey
  $live._validateUpdate = (keys) => {
    const leader = $leader.get()
    if (!$isHost.get() && leader !== ANY_PLAYER_LEADER && leader !== myId) {
      throw new Error(
        `Only the host or leader can update the atom. You can allow all players to make updates by calling $leader.set(ANY_PLAYER_LEADER)`,
      )
    }
  }

  const origSetWithKeys = $live._setWithKeys.bind($live)
  $live._setWithKeys = (newValue, keys) => {
    if (ctx._updateMode !== "confirmed") {
      $live._validateUpdate(keys)
    }

    const oldValue = ($live as any).value
    // NB: Important to call the base method before scheduling livestate sync because:
    // - (a) we need the base method's events to get scheduled before the livestate update gets
    //   scheduled so that any updates that happen in event handlers will get sent in the same
    //   livestate message
    // - (b) if the base method throws an exception (the value didn't actually get set), we don't
    //   want to sync it
    origSetWithKeys(newValue, keys)

    if (ctx._updateMode === "confirmed") {
      return
    }

    const pendingUpdates = ctx._scheduleUpdate()
    const unconfirmedVersion = getOrSet(ctx._unconfirmedVersions, $live, () => new Map())
    if (keys === UNKNOWN_KEYS) {
      if (itemSerializer) {
        pendingUpdates.msg.m[liveKey] = itemSerializer(newValue, UNKNOWN_KEYS)
      } else {
        pendingUpdates.msg.s[liveKey] = newValue
      }
      pendingUpdates.items.delete(liveKey)
      // UNKNOWN_KEYS supersedes all individual unconfirmed items
      unconfirmedVersion.clear()
      unconfirmedVersion.set(UNKNOWN_KEYS, ctx._stateVersion)
    } else {
      if (!itemSerializer) throw Error("itemSerializer required for liveAtom that uses setItem")
      if (liveKey in pendingUpdates.msg.m) {
        pendingUpdates.msg.m[liveKey] = itemSerializer(newValue, UNKNOWN_KEYS)
      } else {
        const itemUpdates = getOrSet(pendingUpdates.items, liveKey, () => new Map())
        const thisItemUpdates = itemSerializer(newValue, keys) as unknown[][]
        for (const [key, value] of thisItemUpdates) {
          itemUpdates.set(key, value)
          unconfirmedVersion.set(key as string, ctx._stateVersion)
        }
      }
    }
    for (const listener of localSetListeners) {
      listener(newValue, oldValue)
    }
  }

  // Adds a listener that is called whenever a call to set() is made. The listener does not get
  // called when updates are applied from the livestate server. There is no batching, and it will be
  // called immediately even if you are using pessimisticBatch (this enables the use case of, e.g.,
  // scheduling other pessimistic updates at the same time).
  //
  // This is useful for building abstractions around atoms, where a call to set() needs to trigger
  // actions, but you don't want to trigger those actions in response to incoming synced changes.
  // (Even if the code is designed so that only one client ever updates a certain atom, you'll still
  // get incoming changes for it when reconnecting to a server that already has that state.)
  $live.onLocalSet = (listener) => {
    localSetListeners.push(listener)
    return () => {
      const index = localSetListeners.indexOf(listener)
      if (index !== -1) {
        localSetListeners.splice(index, 1)
      }
    }
  }

  return $live
}

export function liveAtom<T = undefined>(liveKey: string, ctx?: LiveContext): LiveAtom<T | undefined>
export function liveAtom<T>(liveKey: string, value: T, ctx?: LiveContext): LiveAtom<T>
export function liveAtom<T>(liveKey: string, value?: T, ctx = live): LiveAtom<T> {
  return livify(liveKey, () => atom(value as T), undefined, ctx)
}

export function liveSetAtom<T = unknown>(liveKey: string, value?: Set<T>, ctx?: LiveContext) {
  return livify(
    liveKey,
    () => setAtom(value),
    (value, keys) => {
      const keysIter = keys === UNKNOWN_KEYS ? value.keys() : keys
      return map(keysIter, (key) => [key, value.has(key as any) ? true : REMOVE_ITEM])
    },
    ctx,
  )
}

export function liveMapAtom<K = unknown, V = unknown>(
  liveKey: string,
  value?: Map<K, V>,
  ctx?: LiveContext,
) {
  return livify(
    liveKey,
    () => mapAtom(value),
    (value, keys) => {
      const keysIter = keys === UNKNOWN_KEYS ? value.keys() : keys
      return map(keysIter, (key) => [
        key,
        value.has(key as any) ? value.get(key as any) : REMOVE_ITEM,
      ])
    },
    ctx,
  )
}

export type PlayerAtom<T = unknown> = Live<WritableMapAtom<PlayerId, T>> & {
  $mine: Atom<T | undefined>
}

export function playerAtom<T = undefined>(liveKey: string, ctx = live): PlayerAtom<T> {
  const $atom = liveMapAtom(liveKey, undefined, ctx) as PlayerAtom<T>
  const origValidateUpdate = $atom._validateUpdate
  $atom._validateUpdate = (keys) => {
    // Allow setting own key
    if (keys !== UNKNOWN_KEYS && keys.length === 1 && keys[0] === myId) return
    origValidateUpdate(keys)
  }
  // NB: Using a getter here so it's lazy
  Object.defineProperty($atom, "$mine", {
    get: () => {
      // $atom.$item is already memoized
      return $atom.$item(myId)
    },
  })
  return $atom
}

export function atomGroup() {
  const liveAtoms: LiveAtom[] = []
  const localAtoms: WritableAtom[] = []
  function resetAtoms(atoms: WritableAtom[]) {
    batch(() => {
      for (const $atom of atoms) $atom.reset()
    })
  }
  function addToGroup<T extends WritableAtom<any>>($atom: T): T {
    const atoms = isLiveAtom($atom) ? liveAtoms : localAtoms
    atoms.push($atom)
    return $atom
  }
  addToGroup.liveAtoms = liveAtoms
  addToGroup.localAtoms = localAtoms
  addToGroup.resetLocalAtoms = () => resetAtoms(localAtoms)
  addToGroup.resetLiveAtoms = () => resetAtoms(liveAtoms)
  return addToGroup
}

export const roundScoped = atomGroup()
