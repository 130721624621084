import {liveAtom, roundScoped} from "../../livestate/liveAtom"
import {useAtom} from "../../xignal/react"
import {Submission} from "../components/Submission"
import {HostContinueButton} from "../components/components"
import {twScreenTitle} from "../components/styles"
import {$round, $theme} from "../game"
import {cn} from "../utils/css"
import {setInterstitial} from "./interstitials"

const $showcaseIndex = roundScoped(liveAtom("showcaseIndex", 0))

export type ShowcaseScreenProps = {submittedIds: string[]}
export function ShowcaseScreen({submittedIds}: ShowcaseScreenProps) {
  const round = useAtom($round)
  const theme = useAtom($theme)
  const showcaseIndex = useAtom($showcaseIndex)
  const playerId = submittedIds[showcaseIndex]!

  return (
    <div>
      <div className={cn(twScreenTitle, "italic")}>{theme}</div>
      <Submission playerId={playerId} />
      <HostContinueButton
        onClick={() => {
          const done = showcaseIndex >= submittedIds.length - 1

          if (done) {
            setInterstitial("vote", "VoteScreen", {submittedIds})
          } else {
            $showcaseIndex.set(showcaseIndex + 1)
          }
        }}
      />
    </div>
  )
}
