import {useNavigate, useParams} from "react-router-dom"
import {$avatars, $names} from "../livestate/playerAtoms"
import {useLiveRoom} from "../livestate/react"
import {useAtom} from "../xignal/react"
import {twButton, twRootLayout} from "./components/styles"
import {$screen, screenScoped} from "./game"
import {Loading} from "./Layout"
import {useResetLayout} from "./react/hooks"
import {FinalVoteScreen} from "./screens/FinalVoteScreen"
import {InterstitialScreen} from "./screens/InterstitialScreen"
import {AvatarScreen, Lobby, NameScreen} from "./screens/Lobby"
import {RatingsScreen, RoundWinnerScreen} from "./screens/RatingsScreen"
import {ScoreScreen} from "./screens/ScoreScreen"
import {ShowcaseScreen} from "./screens/ShowcaseScreen"
import {ThemeScreen} from "./screens/ThemeScreen"
import {VoteResultsScreen} from "./screens/VoteResultsScreen"
import {VoteScreen} from "./screens/VoteScreen"
import {WinnerScreen} from "./screens/WinnerScreen"
import {WriteScreen} from "./screens/WriteScreen"

export type ScreenName = keyof typeof screens
export type ScreenProps<T extends ScreenName = ScreenName> = Parameters<(typeof screens)[T]>[0]
// This should only be used in this file. Only the types above should need to be exported,
// otherwise you end up with circular dependencies that break HMR.
const screens = {
  Lobby,
  ThemeScreen,
  WriteScreen,
  RatingsScreen,
  RoundWinnerScreen,
  ShowcaseScreen,
  VoteScreen,
  VoteResultsScreen,
  ScoreScreen,
  InterstitialScreen,
  WinnerScreen,
  FinalVoteScreen,
} as const

function isSameScreen(
  [newScreenName, newProps]: [ScreenName, ScreenProps],
  [oldScreenName, oldProps]: [ScreenName, ScreenProps],
) {
  // NB: we could limit resetting to only when changing away from a specific screen, but doing
  // it unconditionally is simpler and just as fast since it will be a no-op if the atom is
  // already in the reset state.
  if (newScreenName !== oldScreenName) {
    return false
  }
  const getKey = (screens[newScreenName] as any).getKey
  if (getKey && getKey(newProps) !== getKey(oldProps)) {
    return false
  }
  return true
}

$screen.onChange((newScreen, oldScreen) => {
  if (!isSameScreen(newScreen, oldScreen)) {
    screenScoped.resetLocalAtoms()
  }
})

$screen.onLocalSet((newScreen, oldScreen) => {
  if (!isSameScreen(newScreen, oldScreen)) {
    screenScoped.resetLiveAtoms()
  }
})

export function Play() {
  const {roomCode} = useParams() as {roomCode: string} // roomCode is required
  const navigate = useNavigate()
  const roomState = useLiveRoom(roomCode)

  if (roomState.status === "pending") return <Loading />
  if (roomState.status === "connected") return <Game />
  return (
    <div>
      <div className="text-lg py-2">{String(roomState.error)}</div>
      <button className={twButton} onClick={() => navigate("/join")}>
        OK
      </button>
    </div>
  )
}

function Game() {
  const name = useAtom($names.$mine)
  const avatar = useAtom($avatars.$mine)
  const screenState = useAtom($screen)
  useResetLayout([screenState])

  const [screenName, screenProps] = screenState
  const Screen = screens[screenName]
  const key = (Screen as any).getKey?.(screenProps)

  return (
    <div className={twRootLayout}>
      {name ? (
        avatar ? (
          <Screen {...(screenProps as any)} key={key} />
        ) : (
          <AvatarScreen />
        )
      ) : (
        <NameScreen />
      )}
    </div>
  )
}
