import {useEffect, useReducer} from "react"
import {$isHost} from "../livestate/liveContext"
import {useEffectEvent} from "../src/react/hooks"
import {Atom} from "./atom"
import {effect} from "./computed"

export function useAtom<T>($atom: Atom<T>) {
  // NB: We always return the current value of $atom, rather than storing that value in react state
  // and returning that, because whe autoDeferredBatching is true, there can be times where we don't
  // get the onChange event (probably just in Strict mode?). Also, this approach is consistent with
  // what Jotai does.
  const [_, forceUpdate] = useReducer((x) => x + 1, 0)
  useEffect(() => {
    return $atom.onChange((value) => {
      forceUpdate()
    })
  }, [$atom])

  return $atom.get()
}

export function useOnceWhenHostAnd($condition: Atom<unknown>, event: () => void) {
  const effectEvent = useEffectEvent(event)
  useEffect(() => {
    let needsFirstRunUnsub = false
    // This handles the case of unsubscribing synchronously on the first run of the effect.
    // We can't call the effect's own unsub function because it hasn't been returned yet.
    let unsub = () => {
      needsFirstRunUnsub = true
    }
    unsub = effect((watch) => {
      if (watch($isHost) && watch($condition)) {
        unsub()
        effectEvent()
      }
    })
    if (needsFirstRunUnsub) unsub()
    return unsub
  }, [$condition, effectEvent])
}
