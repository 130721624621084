import {$names} from "../../livestate/playerAtoms"
import {ordinalName} from "../../shared/utils/builtins"
import {useAtom} from "../../xignal/react"
import {AvatarIcon} from "../AvatarIcon"
import {Submission} from "../components/Submission"
import {HostContinueButton} from "../components/components"
import {twScreenTitle} from "../components/styles"
import {$round, $scores, $theme, finalRound, setScreen} from "../game"
import {cn} from "../utils/css"

type VoteResult = {playerId: string; voterIds: string[]}
type VoteResultsScreenProps = {
  voteResults: VoteResult[]
}

export function VoteResultsScreen({voteResults}: VoteResultsScreenProps) {
  const theme = useAtom($theme)
  const twHeaderItem = "py-[5px] px-2 border-r last:border-r-0 border-yellow-800"
  const iconSize = 28
  return (
    <div>
      <div className={cn(twScreenTitle, "italic")}>{theme}</div>
      {voteResults.map((result, index) => {
        const voteCount = result.voterIds.length
        const voteSuffix = voteCount === 0 ? "s" : voteCount === 1 ? ":" : "s:"
        const rank = index + 1
        const placePrefix = rank === 1 ? "🏆 " : ""
        const placeText = placePrefix + ordinalName(rank)
        const playerId = result.playerId
        return (
          <div key={playerId}>
            <Submission
              playerId={playerId}
              header={
                <div className="flex bg-yellow-200 border-b border-yellow-800 rounded-t-md whitespace-nowrap">
                  <div className={cn(twHeaderItem, "text-xl flex items-center")}>{placeText}</div>
                  <div className={cn(twHeaderItem, "flex items-center gap-1.5")}>
                    <AvatarIcon size={iconSize} playerId={playerId} />
                    <div>{$names.getItem(playerId)}</div>
                  </div>
                  <div className={cn(twHeaderItem, "flex items-center overflow-hidden")}>
                    {voteCount} vote{voteSuffix}
                    <div className="flex items-center px-1 gap-px">
                      {result.voterIds.map((voterId) => (
                        <AvatarIcon key={voterId} playerId={voterId} size={iconSize} />
                      ))}
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        )
      })}
      <HostContinueButton
        onClick={() => {
          updateScores(voteResults)
          setScreen("ScoreScreen", {})
        }}
      />
    </div>
  )
}

function updateScores(voteResults: VoteResult[]) {
  const multiplier = $round.get() === finalRound ? 2 : 1
  let first = true
  for (const voteResult of voteResults) {
    let score = $scores.getItem(voteResult.playerId) ?? 0
    score += voteResult.voterIds.length * 10 * multiplier
    if (first) {
      score += 5 * multiplier
      first = false
    }
    $scores.setItem(voteResult.playerId, score)
  }
}
