import {WritableAtom} from "../xignal/atom"
import {computed} from "../xignal/computed"
import {atomGroup, liveSetAtom, playerAtom} from "./liveAtom"
import {$hostId, $playerIds, PlayerId} from "./liveContext"

export const multiGameScoped = atomGroup()
// We don't actually need the atoms in multiGameScoped to be Writable, and even if we did,
// $playerIds actually is writable (but is typed as readonly), so this is fine.
multiGameScoped($playerIds as any as WritableAtom)
multiGameScoped($hostId)

export const $names = multiGameScoped(playerAtom<string>("names"))
export const $botIds = multiGameScoped(liveSetAtom<PlayerId>("botIds"))
export const $playerAndBotIds = computed(($) => {
  const playerIds = $($playerIds)
  const botIds = $($botIds)
  const bothIds = new Set(playerIds)
  for (const botId of botIds) {
    bothIds.add(botId)
  }
  return bothIds
})
export const $avatars = multiGameScoped(playerAtom<number>("avatars"))

export function avatarUrl(avatarId: number) {
  return `/characters/${avatarId}.jpeg`
}
