import {Atom, atom, epoch} from "./atom"
import {Unsubscribe} from "./events"

export type Cleanup = () => void
export interface Watcher {
  <W>($atom: Atom<W>): W
  onCleanup: (cleanup: Cleanup) => void
}

export function computed<T>(compute: (watch: Watcher) => T): Atom<T> {
  let deps: Map<Atom, any>
  let mounted = false
  let currentEpoch: number
  const cleanups: Cleanup[] = []
  // We will update the value before it is ever observed externally, so casting to T is safe
  const $computed = atom(undefined as T)

  let origGet = $computed.get.bind($computed)
  $computed.get = () => {
    if (currentEpoch !== epoch) {
      if (deps) {
        for (const [$dep, prevDepValue] of deps) {
          if ($dep.get() !== prevDepValue) {
            update()
            break
          }
        }
        // Nothing changed. (Note: currentEpoch is also updated in update().)
        currentEpoch = epoch
      } else {
        deps = new Map()
        update()
      }
    }
    return origGet()
  }

  function doCleanup() {
    for (const cleanup of cleanups) cleanup()
    cleanups.length = 0
  }

  function update() {
    doCleanup()
    deps.clear()
    let tooLate = false
    const watch: Watcher = ($atom) => {
      if (tooLate) {
        throw Error("You can only call `watch` synchronously inside the `computed` callback.")
      }
      const isNewDep = !deps.has($atom)
      // Must subscribe first in case $atom needs to be mounted
      if (isNewDep && mounted) {
        cleanups.push($atom.onChange(update))
      }
      const value = $atom.get()
      // Keep the first value observed for dirty checking, for consistency
      if (isNewDep) {
        deps.set($atom, value)
      }
      return value
    }
    watch.onCleanup = (cleanup) => {
      cleanups.push(cleanup)
    }
    const newValue = compute(watch)
    tooLate = true
    $computed.set(newValue)
    currentEpoch = epoch
  }

  $computed.onMount(() => {
    mounted = true
    if (deps) {
      for (const $dep of deps.keys()) {
        cleanups.push($dep.onChange(update))
      }
    }
    return () => {
      mounted = false
      doCleanup()
    }
  })

  return $computed
}

const NOOP = () => {}

export function effect(compute: (watch: Watcher) => void | (() => void)): Unsubscribe {
  return computed((watch) => {
    const cleanup = compute(watch)
    if (cleanup) watch.onCleanup(cleanup)
  }).onChange(NOOP) // Subscribe to force mount
}
