import {$isHost} from "../../livestate/liveContext"
import {useAtom} from "../../xignal/react"
import {useResetLayout} from "../react/hooks"
import {cn} from "../utils/css"
import {twButton} from "./styles"

export function WaitingForOthers() {
  useResetLayout([])
  return <div className="font-bold text-center">Waiting for other players...</div>
}
export function HostButton({
  label,
  onClick,
  className,
}: {
  label: string
  onClick?: () => void
  className?: string
}) {
  const isHost = useAtom($isHost)
  if (!isHost) return null
  return (
    <button className={cn(twButton, "mb-4", className)} onClick={onClick}>
      {label}
    </button>
  )
}
export function HostContinueButton({onClick}: {onClick: () => void}) {
  return <HostButton label="Continue" onClick={onClick} />
}
