import * as Sentry from "@sentry/react"
import {Suspense} from "react"
import {Outlet} from "react-router-dom"
import {$connected, $joined} from "../livestate/liveContext.ts"
import {atom} from "../xignal/atom.ts"
import {useAtom} from "../xignal/react.ts"
import robotDanceGif from "./assets/robot-dance.gif"
import {ErrorScreen} from "./screens/ErrorScreen.tsx"

const $navigatorOnline = atom(navigator.onLine)
window.addEventListener("offline", () => $navigatorOnline.set(false))
window.addEventListener("online", () => $navigatorOnline.set(true))

export function Loading() {
  return <div className="py-10">Loading...</div>
}

const twOverlay = "absolute inset-0 bg-black/70 flex items-center justify-center text-lg text-white"

function ConnectionOverlay() {
  const joined = useAtom($joined)
  const online = useAtom($navigatorOnline)
  const connected = useAtom($connected)

  if (!joined) return

  if (!online) {
    return (
      <div className={twOverlay}>
        <div>No internet connection</div>
      </div>
    )
  }
  if (!connected) {
    return (
      <div className={twOverlay}>
        <div>Reconnecting to server...</div>
      </div>
    )
  }
  return
}

export function Layout() {
  return (
    // This should stay at the top level of the layout so we catch all errors.
    // See RouterError.tsx for why we don't rely on that.
    <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
      {/* Not prefetching error.gif b/c it's pretty big */}
      <link rel="prefetch" href={robotDanceGif} />
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
      <ConnectionOverlay />
    </Sentry.ErrorBoundary>
  )
}
