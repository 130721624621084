import posthog from "posthog-js"
import Swal from "sweetalert2"
import IconQuestionCircle from "../icons/IconQuestionCircle"
import {cn} from "../utils/css"
import {twIcon, twLink} from "./styles"

export function HowToPlayLink() {
  return (
    <button className={cn(twLink)} onClick={showHowToPlay}>
      <IconQuestionCircle className={cn(twIcon, "-top-px")} />
      How to play
    </button>
  )
}

function showHowToPlay() {
  posthog.capture("howToPlay:click")
  void Swal.fire({
    title: "How to play",
    customClass: {
      container: "how-to-play",
    },
    html: `
    <ol><li>
    In each round, fill in the blank to get 3 AI-generated images based on your prompt. Choose the one you like best.
    </li><li>
    Everyone rates the chosen images. The best rating wins the round (ties go to whoever submitted their image first).
    </li><li>
    Everyone votes for one of the 4 round-winning images to decide the overall game-winning image.
    </li></ol>`,
  })
}
