import {useEffect, useState} from "react"
import {$leader, Leader, live} from "./liveContext"

export function useLiveLeader(newLeader: Leader, ctx = live) {
  useEffect(() => {
    const oldLeader = $leader.get()
    $leader.set(newLeader)
    return () => {
      $leader.set(oldLeader)
    }
  }, [newLeader])
}

const pendingState = Object.freeze({status: "pending"})
const connectedState = Object.freeze({status: "connected"})
type RoomState = typeof pendingState | typeof connectedState | {status: "error"; error: unknown}

export function useLiveRoom(roomCode: string, ctx = live): RoomState {
  const [roomState, setRoomState] = useState<RoomState>(pendingState)
  roomCode = roomCode.toUpperCase()

  useEffect(() => {
    let canceled = false
    setRoomState(pendingState)
    ctx
      .joinRoom(roomCode)
      .then(() => {
        if (canceled) return
        setRoomState(connectedState)
      })
      .catch((error) => {
        if (canceled) return
        setRoomState({status: "error", error})
      })
    return () => {
      canceled = true
      ctx.leaveRoom()
    }
  }, [roomCode, ctx])

  return roomState
}
