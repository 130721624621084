import {ReactNode, Suspense, use} from "react"
import {ErrorBoundary, ErrorBoundaryPropsWithFallback} from "react-error-boundary"

export interface UsePromiseProps<T> {
  promise: Promise<T>
  children: (resolved: T) => ReactNode
}

export interface UseProps<T> extends UsePromiseProps<T> {
  errorFallback: ErrorBoundaryPropsWithFallback["fallback"]
  suspenseFallback: ReactNode
}

export function Use<T>({promise, errorFallback, suspenseFallback, children}: UseProps<T>) {
  return (
    <ErrorBoundary fallback={errorFallback}>
      <Suspense fallback={suspenseFallback}>
        <UsePromise promise={promise}>{children}</UsePromise>
      </Suspense>
    </ErrorBoundary>
  )
}

function UsePromise<T>({promise, children}: UsePromiseProps<T>) {
  const resolved = use(promise)
  return children(resolved)
}
