/////
// This file should be kept in sync between the livestate server and client repos.
/////

import SuperJSON from "superjson"
import {z} from "zod"

export const REMOVE_ITEM = Symbol("REMOVE_ITEM")
SuperJSON.registerSymbol(REMOVE_ITEM)

// Duplicated from utils/builtins.ts so we don't have another shared dependency
function isEmpty(obj: object | null | undefined) {
  for (const prop in obj) return false
  return true
}

const mapItems = z.array(z.tuple([z.unknown(), z.unknown()]))

export const msgSchema = z
  .object({
    // s = set
    s: z.record(z.unknown()),
    // m = map set
    m: z.record(mapItems),
    // i = item set (map)
    i: z.record(mapItems),
    // e = events
    e: z.array(z.tuple([z.string()]).rest(z.unknown())),
    // p = ping/pong
    p: z.number(),
    // v = version/ack-version
    v: z.number(),
  })
  .partial()
export type Msg = z.output<typeof msgSchema>
export type BaseMsg = Omit<Msg, "p">
export type LiveEvents = Required<Msg>["e"]

export function createMsg(version: number): Msg & Required<BaseMsg> {
  return {
    s: Object.create(null),
    m: Object.create(null),
    i: Object.create(null),
    e: [],
    v: version,
  }
}

export function optimizeMsg(msg: Msg) {
  if (isEmpty(msg.s)) delete msg.s
  if (isEmpty(msg.m)) delete msg.m
  if (isEmpty(msg.i)) delete msg.i
  if (isEmpty(msg.e)) delete msg.e
}
